import React, { useState, useEffect } from "react";

const App = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      setCount(count + 1);

      if (count % 2 == 0) {
        console.log("The number is even.");
        window.open(
          "https://education.service.tieto.com/WE.Education.Absence/RegisterLesson/Index#/",
          "_alltidedlevo"
        );
      }
      if (count % 2 == 1) {
        console.log("The number is odd.");
        window.open(
          "https://education.service.tieto.com/WE.Education.Absence/ReportAbsenceByActivities/Index#/",
          "_alltidedlevo"
        );
      }
    }, 18000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="nisse">
      <p>Alltid Edlevo {count}</p>
    </div>
  );
};

export default App;
